
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import axios from 'axios';
  import { getEndpoint, userLanguage } from '@/utils/Helpers';
  import AscxServiceTerms from '@rdss-mono/ascx-service-terms';

  @Component({
    components: {
      AscxServiceTerms
    }
  })
  export default class PrivacyPolicy extends Vue {
    private loaded = false;
    private privacy = null;

    private get acceptedTos(): boolean {
      return this.$store.getters.user.accepted_tos;
    }

    @Watch('language')
    onLanguageChanged(): void {
      this.loadPrivacyPolicy();
    }

    private get language(): string {
      return this.$store.getters.language;
    }

    public loadPrivacyPolicy(): void {
      const loader = this.$loading.show();
      const tosUrl = `terms-of-service/${userLanguage(
        process.env.VUE_APP_LANGUAGE,
        this.language,
        this.$store.getters.user.language
      )}/privacy_policy.html`;
      axios
        .get(
          `${getEndpoint(
            process.env.VUE_APP_ENDPOINT,
            false
          )}s3/get-file?s3Path=${tosUrl}`
        )
        .then(({ data }) => {
          this.privacy = data;
          this.loaded = true;
        })
        .catch((error) => {
          /**
           * default behavior
           */
          console.log('error', error);
        })
        .finally(() => {
          loader.hide();
        });
    }

    mounted(): void {
      this.loadPrivacyPolicy();
    }
  }
